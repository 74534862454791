import {
  Application,
  CitizenshipStatus,
  MaritalStatus,
  Subscription,
} from '@acorn/util';

export interface CustomerUser {
  id: string;
  email: string;
  role: 'User';
  confirmedFSG: boolean;
  currentApplication?: Application | null;
  currentSubscription: Subscription | null;
}

export interface CustomerProfile {
  phoneNumber: string; // not found on UI
  fullName: string; // not found on UI
  email: string;
  avatarUrl: string; // not found on UI
  // status: UserStatus; // not found on UI
  gender: string;
  dateOfBirth: string;
  address: string;
  maritalStatus: MaritalStatus;
  citizenshipStatus: CitizenshipStatus;
  firstName: string;
  middleName: string;
  lastName: string;
  children: string;
  elderlyParent: string;
  lovedOneWithSpecialNeed: string;
  partnerFirstName: string;
  partnerDateOfBirth: string;
  partnerMaritalStatus: MaritalStatus;
  partnerCitizenshipStatus: CitizenshipStatus;
  partnerChildren: string;
  partnerElderlyParent: string;
  partnerLovedOneWithSpecialNeed: string;
}

export interface ProfileFormValue {
  firstName?: string | null;
  dateOfBirth?: string | null;
  email?: string | null;
  citizenshipStatus?: CitizenshipStatus | null;
  maritalStatus?: MaritalStatus | null;
  partnerFirstName?: string | null;
  partnerDateOfBirth?: string | null;
  partnerCitizenshipStatus?: CitizenshipStatus | null;
  children?: string[] | null;
  elderlyParent?: string[] | null;
  lovedOneWithSpecialNeed?: string[] | null;
  partnerChildren?: string[] | null;
  partnerElderlyParent?: string[] | null;
  partnerLovedOneWithSpecialNeed?: string[] | null;
}
