export enum ApplicationStep {
    GettingStarted = 'GettingStarted',
    AboutYou = 'AboutYou',
    Goal = 'Goal',
    FinancialSnapshot = 'FinancialSituation',
    RiskTolerance = 'RiskTolerance',
    WealthHealth = 'WealthHealth',
    FinancialProjection = 'FinancialProjection',
    WealthStrategyOptions = 'WealthStrategyOption',
    WealthCoach = 'WealthCoach',
    Dashboard = 'Dashboard',
}

export enum FinancialCategoryType{
    Income = 'Income',
    Assets = 'Assets',
    Liabilities = 'Liabilities',
    Expenses = 'Expenses',
}

export type ApplicationSubStep =
  | GoalSettingStep
  | WealthHealthStep
  | WealthCoachStep
  | null;

export type ApplicationSubPartStep = GoalSettingPartsStep | null;

export enum GoalStep {
  Round1 = 'Round1',
  Round2 = 'Round2',
  Round3 = 'Round3',
  RoundVerify = 'RoundVerify',
  GoalMetrics = 'GoalMetrics',
}

export enum WealthHealthStep {
  WealthBuildingBlock = 'WealthBuildingBlock',
  WealthScore = 'WealthScore',
  WealthHappy = 'WealthHappy',
}

export enum WealthCoachStep {
  Schedule = 'Schedule',
  WelcomeBack = 'WelcomeBack',
  WelcomeBackEditable = 'WelcomeBackEditable',
}

export enum WealthStrategyOptionsStep{
  WelcomeBack = 'WelcomeBackRiskTolerance',
  WealthStrategyOptions = 'WealthStrategyOption',
  WhatsNext = 'WhatsNext',
}

export enum PartStep {
  Part1 = 'Part1',
  Part2 = 'Part2',
  Part3 = 'Part3',
  Part4 = 'Part4',
  Part5 = 'Part5',
  YourTop5 = 'YourTop5',
  DefineYourMetrics = 'DefineYourMetrics',
}

export enum GoalSettingPartStep {
  BuildWealth = 'BuildWealth',
  FinancialSecurity = 'FinancialSecurity',
  LifestyleDevelopment = 'LifestyleAndDevelopment',
  FamilyCommunity = 'FamilyAndCommunity',
}

export enum GoalSettingStep {
  GoalSetting = 'Round1',
  RoundVerify = 'RoundVerify',
  GoalMetrics = 'GoalMetrics',
}

export enum   GoalSettingPartsStep {
  ChooseYourGoals = 'ChooseYourGoals',
  Part1 = 'Part1',
  Part2 = 'Part2',
  Part3 = 'Part3',
  Part4 = 'Part4',
  YourTop5 = 'YourTop5',
  DefineYourMetrics = 'DefineYourMetrics',
}

export enum GoalPartStep {
  Part1 = 'Part1',
  Part2 = 'Part2',
  Part3 = 'Part3',
  Part4 = 'Part4',
}
