import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from '@azure/msal-browser';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_acorn_client',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://nolacorn.b2clogin.com/NOLACORN.onmicrosoft.com/B2C_1_acorn_client',
    },
  },
  authorityDomain: 'nolacorn.b2clogin.com',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: '7eda4bde-303c-4583-90de-c71a58178b3c',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: [],
};
