import {
  CommonResponse,
  CustomerUser,
  QueryListResponse,
  UserUpload,
} from '@acorn/util';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env';

import { isEmpty, isNil, omit } from 'lodash';
import { Observable } from 'rxjs';

import { UserRole } from '../utils/enum';

import { User, UserFilter, UserProfile } from '../utils/interfaces';

@Injectable({ providedIn: 'root' })
export class UserService {
  #httpClient = inject(HttpClient);

  getUserById(userId: string) {
    return this.#httpClient.get<CommonResponse<User>>(
      `${environment.apiUrls.admin}/api/user/${userId}`
    );
  }

  getCustomerById(id: string) {
    return this.#httpClient.get<CommonResponse<CustomerUser>>(
      `${environment.apiUrls.customer}/api/User/${id}`
    );
  }

  updateUserById(userId: string, userInformation: Partial<User>) {
    return this.#httpClient.put<CommonResponse<User>>(
      `${environment.apiUrls.admin}/api/user/${userId}`,
      userInformation
    );
  }

  createUser(
    userModel: Pick<User, 'phoneNumber' | 'email' | 'fullName' | 'firstName'>,
    userRole: UserRole
  ) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.admin}/api/user`,
      {
        ...userModel,
        role: userRole,
      }
    );
  }

  getUsers(filter: UserFilter) {
    return this.#httpClient.post<QueryListResponse<User[]>>(
      `${environment.apiUrls.admin}/api/user/get-list`,
      { ...filter, pageNumber: filter.pageNumber + 1 }
    );
  }

  getUsersUpload(keyword: string) {
    return this.#httpClient.post<CommonResponse<UserUpload[]>>(
      `${environment.apiUrls.admin}/api/User/get-users-upload?keyword=${keyword}`,
      {}
    );
  }

  updateAccountStatusById(userId: string, user: User) {
    if (isNil(user.firstName)) user.firstName = '';
    return this.#httpClient.put<CommonResponse<User>>(
      `${environment.apiUrls.admin}/api/user/${userId}/status`,
      { status: user.status }
    );
  }

  removeAccountById(userId: string) {
    return this.#httpClient.delete<CommonResponse<User>>(
      `${environment.apiUrls.admin}/api/user/${userId}`
    );
  }

  resetPasswordByAdmin(email: string) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.admin}/api/user/reset-password-by-admin`,
      {
        email,
      }
    );
  }

  getUserProfile() {
    return this.#httpClient.get<CommonResponse<UserProfile>>(
      `${environment.apiUrls.admin}/api/userProfile/current`
    );
  }

  getUsersRequestHandler(
    oldFilter: UserFilter,
    filter: Partial<UserFilter> | undefined,
    userRole: UserRole[]
  ) {
    let newFilter: UserFilter = {
      ...oldFilter,
      ...filter,
      roleNames: userRole,
    };

    if (newFilter.status === 'None') {
      delete newFilter.status;
    }

    if (isEmpty(newFilter.sortDirection)) {
      newFilter = omit(newFilter, ['sortBy', 'sortDirection']);
    }

    return newFilter;
  }

  importPreApproveMail(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.#httpClient.post<CommonResponse<string>>(
      `${environment.apiUrls.admin}/api/PreApproveMail/import`,
      formData
    );
  }

  getWealthCoachCalendarLink(): Observable<CommonResponse<string>> {
    return this.#httpClient.get<CommonResponse<string>>(
      `${environment.apiUrls.customer}/api/User/Calendar`
    );
  }
}
