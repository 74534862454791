import { CategoryCode, CategoryStatus, CategoryType } from '@acorn/util';

export interface CategoryResponse {
  name: string;
  parentId: string;
  displayParentId?: string;
  description: string;
  order: number;
  displayOrder?: number;
  isDualRespondent: boolean;
  id: string;
  categoryCode: CategoryCode;
  type: CategoryType;
  status: CategoryStatus;
}

export interface Category extends CategoryResponse {
  isFirstItem: boolean;
  isLastItem: boolean;
  childItems?: Category[];
  // milestone2-changes
  data?: any;
  displayChildItems? : Category[];
}

export interface CategoryStatusInputModel {
  categoryCode: CategoryCode;
  status: CategoryStatus;
}
