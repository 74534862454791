import { Environment } from '../libs/util/src';

export const environment: Environment = {
  production: false,
  subscriptionKey: 'fd0ba65d1a3b4c998088dc892ca87adf',
  wordpressURL: 'https://www.wealthmaximiser.com.au/',
  calendlySupportTeamUrl:
    'https://calendly.com/tai-vo-cfm/30min?primary_color=f17b24',
  apiUrls: {
    identity: 'https://dev-acorn-apim-api-v2.azure-api.net/identity',
    admin: 'https://dev-acorn-apim-api-v2.azure-api.net/admin',
    customer: 'https://dev-acorn-apim-api-v2.azure-api.net/acorn',
    calculation: "https://dev-acorn-apim-api-v2.azure-api.net/calculation/api/"
  },
  contentfulConfig: {
    space: 'ic3jwsqvnukq',
    accessToken: '2aUJ4gCc7TYhCRdt3xnaejeky1UuwKLFOqDYw5Z46oA',
    contentTypeIds: {
      homePage: 'page',
      inspirationQuotePage: 'componentQuote',
      customerQuote: 'customerQuote',
      pricingPackage: 'pricingPackage',
      frequentlyAskedQuestion: 'frequentlyAskedQuestion',
    },
    entryIds: {
      homePage: '5wUDvj3Y8Ih1RkV83pZkwd',
      pricingPage: '546Urj4adtmZFxP3Eegfv8',
      aboutPage: '7q0hj8HvhudpwtpWyA3zBo',
      mediaPage: '4y8nmwHoOs874U5vEYHofh',
      wealthPhilosophyPage: '54WF1Eux5uSodQkgdcGaAk',
      bronzePlanComponent: '1KHZ8ST8khE59muOxAVNHM',
      silverPlanComponent: '32ailzBsaFBNaBC0IWKUJk',
      goldPlanComponent: '9VgfH51q2Hte5CcTLyQpb',
      ['privacy-policy']: '7JypC6dpKlcrwuXEWm3soP',
      ['terms-and-conditions']: '6gJFJuSyFsskWRgCvbi9lb',
      ['financial-services-guide']: '35xd2Yhy7RTFyyjZHd8GSJ',
      ['legal-disclaimer']: '2sZpU0NkSBWUGWUTysceoD',
      signUpPage: '6yM1fqmFJY4bx36O2Cey37',
    },
  },
  stripeApiKey:
    'pk_test_51Onu5FLIpa194NIJSkKRn3WwrDWImgLY8bCzoERa1h33cUNqy7Qrfq2cb0scmz2jCAmycsn1bCex6pMR3H851Hdy002u5N6Nui',
  feeFoService: {
    allReviewUrl:
      'https://www.feefo.com/en-GB/reviews/nobleoak-life?displayFeedbackType=SERVICE&timeFrame=YEAR',
  },
  pdfUrl:"https://dev-pdf.wealthmaximiser.com.au/",
  investmentId:'eacf931e-03fc-4149-889c-44290c7ccd04',
  propertyValueId:'c4fd6c7c-170d-4c54-8341-31bbc38756cc',
};
