<div class="container">
  <h4>401</h4>

  <h1>Unauthorized</h1>

  <p class="message">You are not authorized to access this page.</p>

  <button mat-flat-button color="primary" (click)="onSignOut()">
    Go back sign-in
  </button>
</div>
