import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, noop, of, switchMap, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import jwtDecode from 'jwt-decode';
import { MsalService } from '@azure/msal-angular';

import { LocalStorageService } from '@acorn/data-access';

import { SecurityActions } from './security.actions';

import { SecurityService } from '../common/data-access';

import { STORAGE_KEY } from '../common/utils/constants';
import { AuthInfo } from '../common/utils/interfaces';

@Injectable({ providedIn: 'root' })
export class SecurityEffect {
  #actions = inject(Actions);
  #securityService = inject(SecurityService);
  #router = inject(Router);
  #ngxLoader = inject(NgxUiLoaderService);
  #localStorageService = inject(LocalStorageService);
  #authService = inject(MsalService);

  signInSso$ = createEffect(() =>
    this.#actions.pipe(
      ofType(SecurityActions.signInSso),
      switchMap(({ email, token }) =>
        this.#securityService.signInSso(email, token).pipe(
          map(({ isSuccess, data }) => {
            if (!isSuccess) {
              return SecurityActions.signInFailure();
            }

            const { token, refreshToken } = data;
            this.#localStorageService.saveData(
              STORAGE_KEY.authInfo,
              JSON.stringify({ token, refreshToken })
            );

            return SecurityActions.signInSuccess(data);
          }),
          catchError(() => of(SecurityActions.signInFailure()))
        )
      )
    )
  );

  signInSuccess$ = createEffect(() =>
    this.#actions.pipe(
      ofType(SecurityActions.signInSuccess),
      map(({ token }) => {
        // @ts-ignore
        const userId = jwtDecode(token)['jti'];
        this.#router.navigateByUrl('/home').then(noop);
        return SecurityActions.authenticateSuccess({ token, userId });
      })
    )
  );

  signInFailure$ = createEffect(
    () =>
      this.#actions.pipe(
        ofType(SecurityActions.signInFailure),
        tap(() => {
          this.#router.navigate(['/unauthorized']).then(noop);
        })
      ),
    { dispatch: false }
  );

  signOut$ = createEffect(() =>
    this.#actions.pipe(
      ofType(SecurityActions.signOut),
      switchMap(() => {
        this.#ngxLoader.start();
        const authInfo = this.#localStorageService.getData<AuthInfo>(
          STORAGE_KEY.authInfo
        );

        if (!authInfo?.token) {
          return of(SecurityActions.signOutFailure());
        }

        return this.#securityService.signOut(authInfo?.token).pipe(
          map(() => {
            this.#securityService.handleRemoveToken();
            this.#authService.logout();

            return SecurityActions.signOutSuccess();
          }),
          catchError(() => of(SecurityActions.signOutFailure()))
        );
      })
    )
  );
}
