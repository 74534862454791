import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';

import {
  CategoryResponse,
  AboutYouQuestion,
  AboutYouUserAnswer,
  AnswerSelection,
  ApplicationAboutYouResponse,
  CommonResponse,
  APPLICATION_ID,
} from '@acorn/util';

@Injectable()
export class AboutYouService {
  #httpClient = inject(HttpClient);
  #applicationId = inject(APPLICATION_ID);

  getCategories() {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '';

    return this.#httpClient.get<CommonResponse<CategoryResponse[]>>(
      `${environment.apiUrls.customer}/api/AboutYou/app-categories${params}`
    );
  }

  getQuestions() {
    return this.#httpClient.get<CommonResponse<AboutYouQuestion[]>>(
      `${environment.apiUrls.customer}/api/AboutYou/questions`
    );
  }

  getAnswerSelection() {
    return this.#httpClient.get<CommonResponse<AnswerSelection[]>>(
      `${environment.apiUrls.customer}/api/AboutYou/answer-selection`
    );
  }

  getAnswers() {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '/current';

    return this.#httpClient.get<CommonResponse<ApplicationAboutYouResponse[]>>(
      `${environment.apiUrls.customer}/api/AboutYou${params}`
    );
  }

  updateAnswers(applicationId: string, userAnswers: AboutYouUserAnswer[] | any) {
    const params = this.#applicationId.value
      ? `?applicationId=${this.#applicationId.value}`
      : '/current';

    return this.#httpClient.post<CommonResponse<ApplicationAboutYouResponse[]>>(
      `${environment.apiUrls.customer}/api/AboutYou${params}`,
      {
        applicationId,
        userAnswers: userAnswers,
      }
    );
  }
}
