export const ERROR_MESSAGE = {
  INTERNAL_ERROR: 'Internal error',
  FIELD_IS_REQUIRED: 'Field is required.',
  ALL_FIELDS_ARE_REQUIRED: 'All fields are required',
  ALL_ARE_REQUIRED: 'All inputs are required!',
  YOUR_FIRST_NAME_IS_TOO_SHORT: 'Your first name is too short.',
  EMAIL: 'Email is invalid.',
  SIGNUP_FAILURE:
    'Kindly await authorization before gaining access to the website',
  MAKE_A_SELECTION: 'Make a selection',
  SELECT_UP_TO_3: 'Select up to 3',
  SELECT_MANY_OPTIONS: 'Please select at least 1 field',
  ADULT_AGE: 'Age is greater than or equal to 18 years',
  CONFIRM_FSG:
    'Please select ‘I confirm I have read the financial \n' +
    'services guide’ to continue',
};

export const ERROR_KEY = {
  FIELD_REQUIRED: 'fieldRequired',
  ALL_FIELDS_REQUIRED: 'allFieldRequired',
  REPAYMENT: 'repaymentStructure',
  INTEREST_RATE: 'interestRate',
};

export const WARNING_MESSAGE = {
  RELATED_TO_ASSETS:
    'Please note we cannot provide advice on your assets in another country, only enter details on your assets in Australia going forward. Select close to continue',
  RELATED_TO_INVESTMENT:
    'We will not be providing advice related to these assets at this point, however we will provide advice for all other areas of interest you have selected',
};

export const KNOCKOUT_MESSAGE = {
  CITIZENSHIP:
    'Unfortunately we can not provide advice for Temporary Visa Holders at this time. Please contact info@wealthmaximiser.com.au for more details',
  CITIZENSHIP_FOR_DUAL_RESPONDENTS:
    'Unfortunately we cannot provide advice for Temporary visa holders at this time. We can provide advice for the individual that is an Australian citizen/permanent resident, if that individual would like to continue please contact info@wealthmaximiser.com.au',
};
