import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { NgxMaskDirective } from 'ngx-mask';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { subYears } from 'date-fns';

import {
  CitizenshipStatus,
  CitizenshipStatusOptions,
  CustomerProfile,
  ERROR_MESSAGE,
  MaritalStatus,
  MaritalStatusOptions,
  SEPARATOR,
} from '@acorn/util';

import { DependantComponent } from '../dependant';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';

const DATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'dd/MM/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
    },
};
@Component({
  selector: 'acorn-customer-profile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IconSpriteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    NgxMaskDirective,
    DependantComponent,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
  ],
  providers:[ { provide: MAT_DATE_LOCALE, useValue: enUS },
          {
              provide: DateAdapter,
              useClass: DateFnsAdapter,
              deps: [MAT_DATE_LOCALE],
          },
          { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },],
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerProfileComponent implements OnInit {
  cdr = inject(ChangeDetectorRef);


  @Input() set profile(value: CustomerProfile | null) {
    this.#clearFormGroup();

    if (!value) {
      return;
    }

    this.resetFormGroup(value);
  }
  @Input() disabledEmail = false;
  @Input() hasPartner: boolean = false;

  protected readonly CitizenshipStatusOptions = CitizenshipStatusOptions;
  protected readonly MaritalStatusOptions = MaritalStatusOptions;
  protected readonly DependantMaritalStatus = [
    MaritalStatus.Married,
    MaritalStatus.RelationshipWithSharedFinances,
    MaritalStatus.RelationshipWithSeparateFinances,
  ];
  protected readonly ERROR_MESSAGE = ERROR_MESSAGE;

  formGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    dateOfBirth: new FormControl<string | null>(null, Validators.required),
    email: new FormControl('', Validators.required),
    citizenshipStatus: new FormControl<CitizenshipStatus | null>(
      null,
      Validators.required
    ),
    maritalStatus: new FormControl<MaritalStatus | null>(
      null,
      Validators.required
    ),
    partnerFirstName: new FormControl(''),
    partnerDateOfBirth: new FormControl<string | null>(null),
    partnerCitizenshipStatus: new FormControl<CitizenshipStatus | null>(null),
    children: new FormArray<FormControl<string | null>>([]),
    elderlyParent: new FormArray<FormControl<string | null>>([]),
    lovedOneWithSpecialNeed: new FormArray<FormControl<string | null>>([]),
    partnerChildren: new FormArray<FormControl<string | null>>([]),
    partnerElderlyParent: new FormArray<FormControl<string | null>>([]),
    partnerLovedOneWithSpecialNeed: new FormArray<FormControl<string | null>>(
      []
    ),
  });
  maxDateOfBirth = subYears(new Date(), 20);

  ngOnInit() {
    if (this.disabledEmail) {
      this.formGroup.controls.email.disable();
    }
  }

  resetFormGroup(profile: CustomerProfile) {
    this.formGroup.patchValue({
      firstName: profile.firstName,
      dateOfBirth: profile.dateOfBirth || null,
      email: profile.email,
      citizenshipStatus: profile.citizenshipStatus,
      maritalStatus: profile.maritalStatus,
      partnerFirstName: profile.partnerFirstName,
      partnerDateOfBirth: profile.partnerDateOfBirth || null,
      partnerCitizenshipStatus: profile.partnerCitizenshipStatus,
    });

    this.#bindingFormArray('children', profile.children);
    this.#bindingFormArray('elderlyParent', profile.elderlyParent);
    this.#bindingFormArray(
      'lovedOneWithSpecialNeed',
      profile.lovedOneWithSpecialNeed
    );
    this.#bindingFormArray('partnerChildren', profile.partnerChildren);
    this.#bindingFormArray(
      'partnerElderlyParent',
      profile.partnerElderlyParent
    );
    this.#bindingFormArray(
      'partnerLovedOneWithSpecialNeed',
      profile.partnerLovedOneWithSpecialNeed
    );

    this.formGroup.markAsPristine();
  }

  #bindingFormArray(controlName: string, value?: string | null): void {
    const formArray = this.formGroup.get(controlName) as FormArray;
    formArray.clear();

    if (!value) {
      return;
    }

    value
      .split(SEPARATOR)
      .filter((item) => !!item)
      .forEach((item) => {
        formArray.push(new FormControl<string | null>(item));
      });
  }

  #clearFormGroup(): void {
    this.formGroup.reset();
    this.formGroup.controls.children.clear();
    this.formGroup.controls.elderlyParent.clear();
    this.formGroup.controls.lovedOneWithSpecialNeed.clear();
    this.formGroup.controls.partnerChildren.clear();
    this.formGroup.controls.partnerElderlyParent.clear();
    this.formGroup.controls.partnerLovedOneWithSpecialNeed.clear();
  }
}
