import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'acorn-unauthorized-page',
    templateUrl: 'unauthorized-page.component.html',
    styleUrls: ['unauthorized-page.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule],
})
export class UnauthorizedPageComponent {
    authService = inject(MsalService);

    onSignOut(): void {
        this.authService.initialize().subscribe({
            next: () => {
                this.authService.logout();
            },
        });
    }
}
