export enum CategoryCode {
    PersonalProfileAndRelationship = 'PersonalProfileAndRelationship',
    AboutYou = 'AboutYou',
    MaritalStatus = 'MaritalStatus',
    Dependants = 'Dependants',
    CitizenshipStatus = 'CitizenshipStatus',
    MajorLifeEvent = 'MajorLifeEvent',
    RetirementAge = 'RetirementAge',
    RetirementProgress = 'RetirementProgress',
    FinancialBehaviourAndRiskAnalysis = 'FinancialBehaviourAndRiskAnalysis',
    RiskToYou = 'RiskToYou',
    InvestmentRisk = 'InvestmentRisk',
    FinancialRisk = 'FinancialRisk',
    InvestingExperience = 'InvestingExperience',
    TaxPlanning = 'TaxPlanning',
    PrimaryIncome = 'PrimaryIncome',
    OtherIncome = 'OtherIncome',
    Savings = 'Savings',
    Property = 'Property',
    SupplementIncome = 'SupplementIncome',
    Investments = 'Investments',
    FinancialInvestments = 'FinancialInvestments',
    Insurance = 'Insurance',
    MoneyManagement = 'MoneyManagement',
    LastReview = 'LastReview',
    LegacyProtection = 'LegacyProtection',
    FinancialSnapshot = 'FinancialSnapshot',
    YourSnapshot = 'YourSnapshot',
    RealEstate = 'RealEstate',
    WhoIsThisFor = 'WhoIsThisFor',
    YourDetails = 'YourDetails',
    RiskToleranceOutcome = 'RiskToleranceOutcome',
    SuperFunds = 'SuperFunds',
    WealthBuildingBlocks = 'WealthBuildingBlocks',
    WealthHealthScore = 'WealthHealthScore',
    PropertyOwnership = 'PropertyOwnership',
}

export enum CategoryStatus {
    Pending = 'Pending',
    InProgress = 'InProgress',
    Complete = 'Complete',
}

export enum CategoryType {
    AboutYou = 'AboutYou',
    Financial = 'Financial',
}

export enum WealthHealthCategory {
    IndependenceAndLegacy = 'IndependenceAndLegacy',
    Accumulation = 'Accumulation',
    Foundation = 'Foundation',
}

export enum BuildingBlockType {
    Income = 'Income',
    SavingsAndBudgeting = 'SavingsAndBudgeting',
    TaxAndStructuring = 'TaxAndStructuring',
    WealthProtection = 'WealthProtection',
    SharesAndInvestments = 'SharesAndInvestments',
    Super = 'Super',
    Property = 'Property',
    EstatePlanning = 'EstatePlanning',
    RetirementPlanning = 'RetirementPlanning',
}

export enum riskCategoryType {
    RiskToYou = 'RiskToYou',
    InvestmentRisk = 'InvestmentRisk',
    FinancialRisk = 'FinancialRisk',
    InvestingExperience = 'InvestingExperience',
    InvestingPreference = 'InvestingPreference',
    ProtectionImportance = 'ProtectionImportance',
}

export enum wealthCategoryType {
    Insurance = 'Insurance',
    MoneyManagement = 'MoneyManagement',
    LastReview = 'LastReview',
    LegacyProtection = 'LegacyProtection',
    TaxPlanning = 'TaxPlanning',
    SupplementIncome = 'SupplementIncome',
    Investments = 'Investments',
    SuperFunds = 'SuperFunds',
    WealthBuildingBlocks = 'WealthBuildingBlocks',
    WealthHealthScore = 'WealthHealthScore',
}
