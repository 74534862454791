export interface Environment {
  production: boolean;
  wordpressURL: string;
  subscriptionKey: string;
  calendlySupportTeamUrl: string;
  apiUrls: ApiUrls;
  contentfulConfig: ContentfulConfigEnvironment;
  stripeApiKey: string;
  feeFoService: {
    allReviewUrl: string;
  },
  pdfUrl:string;
}

export interface ApiUrls {
  identity: string;
  admin: string;
  customer: string;
  calculation:string
}

export interface ContentfulConfigEnvironment {
  space: string;
  accessToken: string;
  contentTypeIds: ContentTypeIdsEnvironment;
  entryIds: EntryIdsEnvironment;
}

export interface ContentTypeIdsEnvironment {
  homePage: string;
  inspirationQuotePage: string;
  customerQuote: string;
  pricingPackage: string;
  frequentlyAskedQuestion: string;
}

export interface EntryIdsEnvironment {
  homePage: string;
  pricingPage: string;
  aboutPage: string;
  wealthPhilosophyPage: string;
  bronzePlanComponent: string;
  silverPlanComponent: string;
  goldPlanComponent: string;
  mediaPage: string;
  ['privacy-policy']: string;
  ['terms-and-conditions']: string;
  ['financial-services-guide']: string;
  ['legal-disclaimer']: string;
  signUpPage: string;
}
