import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { CommonResponse, QueryListResponse } from '@acorn/util';
import { environment } from '@env';

import { Filter } from '../utils/interfaces';

@Injectable()
export class DashboardService {
  #httpClient = inject(HttpClient);

  getPreApproveList(filter: Filter) {
    return this.#httpClient.post<
      QueryListResponse<{ email: string; id: string }[]>
    >(`${environment.apiUrls.admin}/api/PreApproveMail/get-list`, filter);
  }

  getWaitlistSettingStatus() {
    return this.#httpClient.get<CommonResponse<boolean>>(
      `${environment.apiUrls.admin}/api/Settings/waitlist-status`
    );
  }

  updateWaitListSettingStatus(status: boolean) {
    return this.#httpClient.put<CommonResponse<boolean>>(
      `${environment.apiUrls.admin}/api/Settings/waitlist-status`,
      { status }
    );
  }

  uploadPDF(file: File, applicationFileType: string) {
    const formData: FormData = new FormData();
    formData.append('File', file);
    formData.append('ApplicationFileType', applicationFileType);

    return this.#httpClient.post<CommonResponse<any>>(
      `${environment.apiUrls.admin}/api/File/upload`,
      formData
    );
  }
}
