import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';

import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { provideToastr } from 'ngx-toastr';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalModule,
} from '@azure/msal-angular';

import { appRoutes } from './app.routes';
import { loginRequest, msalConfig } from './auth.config';

import { appFeature, SecurityEffect } from './store';

import { CustomToastComponent } from '@acorn/common-ui';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { provideEnvironmentNgxMask } from 'ngx-mask';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
    provideAnimations(),
    provideHttpClient(),
    provideStore(),
    provideEffects([SecurityEffect]),
    provideState(appFeature),
    provideStoreDevtools(),
    provideToastr({
      toastComponent: CustomToastComponent,
    }),
    provideEnvironmentNgxMask(),
    importProvidersFrom(
      BrowserAnimationsModule,
      MsalModule,
      NgxUiLoaderModule.forRoot({
        bgsColor: '#F47621',
        bgsSize: 60,
        fgsSize: 60,
        bgsType: 'ball-spin-clockwise',
        fgsColor: '#F47621',
        fgsPosition: 'center-center',
        fgsType: 'circle',
      })
    ),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false, width: '512px' },
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
};
