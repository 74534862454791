import { Route } from '@angular/router';

import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

import { authGuard } from './common/data-access';

import { NotFoundComponent, UnauthorizedPageComponent } from './common/ui';

export const appRoutes: Route[] = [
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent,
  },
  {
    // Needed for handling redirect after login
    path: '',
    component: MsalRedirectComponent,
  },
  {
    path: 'home',
    canActivate: [MsalGuard, authGuard],
    loadChildren: () => import('./home/home.routes').then((c) => c.routes),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
