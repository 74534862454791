import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { LocalStorageService } from '@acorn/data-access';
import { CommonResponse } from '@acorn/util';
import { environment } from '@env';

import { STORAGE_KEY } from '../utils/constants';
import { noop } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SecurityService {
  #httpClient = inject(HttpClient);
  #router = inject(Router);

  #localStorageService = inject(LocalStorageService);

  signIn(userName: string, password: string) {
    return this.#httpClient.post<
      CommonResponse<{ token: string; refreshToken: string }>
    >(`${environment.apiUrls.identity}/api/account/login`, {
      userName,
      password,
    });
  }

  signInSso(email: string, token: string) {
    return this.#httpClient.post<
      CommonResponse<{ token: string; refreshToken: string }>
    >(`${environment.apiUrls.identity}/api/Account/admin/login-sso`, {
      email,
      token,
    });
  }

  signOut(token: string) {
    return this.#httpClient.post<CommonResponse<unknown>>(
      `${environment.apiUrls.identity}/api/Account/logout`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  refreshToken(token: string, refreshToken: string) {
    return this.#httpClient.post<
      CommonResponse<{ token: string; refreshToken: string }>
    >(`${environment.apiUrls.identity}/api/Account/refresh-token`, {
      token,
      refreshToken,
    });
  }

  handleUpdateToken(token: string, refreshToken: string): void {
    this.#localStorageService.saveData(
      STORAGE_KEY.authInfo,
      JSON.stringify({ token, refreshToken })
    );
  }

  handleRemoveToken(): void {
    this.#localStorageService.removeData(STORAGE_KEY.authInfo);
  }

  handleUnauthorizedToken(): void {
    // TODO: remove token from browser's store
    this.#router.navigateByUrl('/login').then(noop);
  }
}
